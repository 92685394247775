.page--ateliers {
  .intro__title {
    margin-bottom: 30px;
  }

  .intro__content {
    margin-bottom: 45px;
  }

  .workshops {
    display: flex;
    flex-direction: column;
    gap: 30px;
    list-style-type: none;

    @media (max-width:767px) {
      gap: 60px;
    }

    .workshop {
      border: $border-regular solid black;
      display: grid;
      grid-template-columns: 250px auto;

      @media (max-width:767px) {
        grid-template-columns: 100%;
        gap: 0;
      }

      .workshop__img {
        width: 250px;
        overflow: hidden;
        display: block;

        @media (max-width:767px) {
          width: 100%;
          max-height: 250px;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
          transition: all .2s linear;
        }

        &:hover {

          img {
            transform: scale(1.02);
          }
        }
      }

      .workshop__flex {
        display: flex;
        padding: 30px;
        gap: 50px;

        @media (max-width:1199px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }

      .workshop__texts {
        width: calc(50% - 25px);

        @media (max-width:1199px) {
          width: 100%;
        }

        .workshop__title, .tags {
          margin-bottom: 20px;
        }

        .base {
          width: fit-content;
          display: inline-block;
          margin-top: 25px;
        }

        .workshop__desc {
          font-size: 16px;
          line-height: 140%;
        }
      }

      .workshop__dates {
        list-style-type: none;
        gap: 20px;
        width: calc(50% - 25px);

        @media (max-width:1199px) {
          width: 100%;
        }
      }
    }
  }
}