.subpage--historique {

  .content {
    margin: 0 auto;

    &.text-content {
      width: 66%;

      @media (max-width:1441px) {
        width: 83%;
      }

      @media (max-width:991px) {
        width: 100%;
    }
  }

  .blocs__text {
    .bloc {
      &:first-child {
        .bloc__title {
          margin-top: initial;
        }

      }
      .bloc__title {
        margin-top: 100px;

        @media (max-width: 767px) {
          margin-top: 80px;
        }
      }
    }
    .bloc__content {
      p {
        margin-top: 30px;
      }
      blockquote {
        width: 90%;
        margin: 0 auto;
      }
    }
  }

  .slider {
    margin-top: 120px;
    height: 520px;

    @media (max-width:991px) {
      margin-top: 100px;
    }

    @media (max-width:767px) {
      margin-top: 60px;
    }

    @media (max-width:576px) {
      height: 100%;
    }
  }

  }
  .slider__legend {
    position: relative;
    top: -30px;
    display: flex;
    justify-content: center;
  }
}